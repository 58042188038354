<template>
  <div class="order-detail">
    <header class="page-header">
      <van-icon name="arrow-left" @click="$router.go(-1)" />
      <span>{{ title }}</span>
      <span></span>
    </header>
    <main class="page-body">
      <section class="padding" v-if="orderData != null">
        <div class="card">
          <template>
            <header class="flexbox space-bt card-header">
              <div>{{ $t('订单号') }}：{{ orderData.sOrderNo }}</div>
              <div class="theme-color">{{ orderData.StatusDesc }}</div>
            </header>
            <div class="card-body flexbox space-bt">
              <div>
                <div>
                  {{ $t('唛头') }}：<span class="theme-color">{{
                    orderData.sShipMark
                  }}</span>
                </div>
                <div>
                  {{ $t('单据日期') }}: {{ orderData.createtime | time }}
                </div>
                <div>
                  {{ $t('收件人') }}：<span class="theme-color">{{
                    orderData.sRecName
                  }}</span>
                </div>
                <div>
                  <span>
                    {{ $t('收货电话') }}：<span class="theme-color">
                      {{ orderData.sRecTel }}</span
                    ></span
                  >
                </div>
                <div>
                  {{ $t('收件地址') }}：{{
                    (orderData.sCnCountryName == null
                      ? ''
                      : orderData.sCnCountryName) +
                      ' ' +
                      (orderData.sRecCity == null ? '' : orderData.sRecCity) +
                      ' ' +
                      (orderData.sAddr1 == null ? '' : orderData.sAddr1) +
                      ' ' +
                      (orderData.sAddr2 == null ? '' : orderData.sAddr2)
                  }}
                </div>
                <div>
                  {{ $t('物品名称') }}：{{ orderData.goods_name | empty }}
                </div>
                <div>{{ $t('备注') }}：{{ orderData.remark | empty }}</div>
                <div class="freight-price">
                  {{ $t('运费合计') }}：{{
                    orderData.fTotalAmount == null
                      ? $t('未出账')
                      : orderData.fTotalAmount
                  }}
                  <span class="pull-right">
                    <i
                      v-if="orderData.bAudit"
                      class="fa fa-checked-o round-label"
                      >{{ $t('已付') }}</i
                    >
                    <b v-else class="text-red">{{ $t('未付') }}</b>
                  </span>
                </div>
                <div v-if="orderData.fDeclareValue >= 0">
                  {{ $t('货值') }}：{{ orderData.fDeclareValue | empty }}
                </div>
                <div v-if="orderData.dDepartTime != null">
                  {{ $t('出发日期') }}：{{ departTime | empty }}
                </div>
                <div v-if="orderData.dArriveTime != null">
                  {{ $t('预计到港') }}：{{ arriveTime | empty }}
                </div>
                <div>
                  {{ $t('运输方式') }}:
                  <span class="round-label"> {{ orderData.transType }}</span>
                </div>
                <div class="flexbox">
                  <div
                    v-if="userInfo.bWatchVol == true && orderData.fTotalVol > 0"
                  >
                    {{ $t('体积') }}：<span class="order-info-item">{{
                      orderData.totalVol | empty
                    }}</span>
                  </div>
                  <div
                    v-if="
                      userInfo.bWatchVol == true && orderData.fTotalWeight > 0
                    "
                  >
                    {{ $t('重量') }}：<span class="order-info-item">{{
                      orderData.totalWeight | empty
                    }}</span>
                  </div>
                  <div>
                    {{ $t('箱数') }} ：<span class="order-info-item">{{
                      orderData.totalBags | empty
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
            <footer class="card-footer">
              <span class="pull-left"
                >{{ $t('目的地') }}：<span class="round-label">
                  {{ orderData.targetPlace }}</span
                >
              </span>
            </footer>
          </template>
        </div>
      </section>
      <section v-else class="padding no-item">
        <div class=""><img :src="emptyImg" /></div>
        <div class="nodesc">{{ $t('没有相关的订单信息') }}</div>
      </section>
      <van-tabs>
        <van-tab :title="$t('Order.包裹明细')">
          <template v-if="pkglist.length > 0">
            <div
              class="card tab-padding"
              v-for="(item, index) in pkglist"
              :key="index"
            >
              <div class="card-body flexbox space-bt">
                <div>
                  <div>
                    {{ $t('快递单号') }}：{{ item.delivery_no | empty }}
                  </div>
                  <div>{{ $t('物品名称') }}：{{ item.goods_name }}</div>
                  <div>
                    {{ $t('备注') }}：<span class="foot-desc">{{
                      item.remark
                    }}</span>
                  </div>
                </div>
                <img
                  @click="onImgPreview(item.sPicUrl)"
                  :src="
                    item.sPicUrl == '' || item.sPicUrl == null
                      ? emptyImg
                      : item.sPicUrl
                  "
                  alt=""
                  :class="item.sPicUrl == '' ? 'noimg' : 'img'"
                />
              </div>
            </div>
          </template>
          <template v-else>
            <section class="padding no-item">
              <div class=""><img :src="emptyImg" /></div>
              <div class="nodesc">{{ $t('没有包裹记录') }}</div>
            </section>
          </template>
        </van-tab>
        <van-tab :title="$t('Order.轨迹')">
          <div class="bg-white" v-if="tracks.length">
            <timeline>
              <timeline-title>
                <div>{{ tracks[0].context }}</div>
                <div class="track-en">{{ tracks[0].en_content }}</div>
                <div class="f-12 time">{{ tracks[0].time }}</div>
              </timeline-title>
              <timeline-item
                v-for="(item, index) in tracks.slice(1)"
                :key="index"
              >
                <div>{{ item.context }}</div>
                <div class="track-en">{{ item.en_context }}</div>
                <div class="time f-12">{{ item.time }}</div>
              </timeline-item>
            </timeline>
          </div>
          <div class="bg-white set-center" v-else>
            <img :src="emptyImg" class="noimg" />
            <div>{{ $t('暂无轨迹，请输入运单号查询') }}</div>
          </div>
        </van-tab>
        <van-tab :title="$t('Order.账单')">
          <div
            class="bg-white card"
            v-if="orderData != null && orderData.ACCBILLID != null"
          >
            <div class="card-body">
              <van-cell-group>
                <!-- <van-cell
                  title="首重/方"
                  :value="fFirstPrice"
                  label="体积计价m³/重量kg"
                />
                <van-cell
                  title="续重/方"
                  :value="orderData.fLastPrice"
                  label="体积计价m³/重量kg"
                />
                <van-cell
                  title="计费方式"
                  :value="orderData.FK_BargainID"
                  label="体积/重量"
                /> -->
                <van-cell
                  :title="$t('账单编号')"
                  :value="orderData.ACCBILLNO"
                />
                <van-cell
                  :title="$t('账单状态')"
                  :value="orderData.ACCBILLStatus"
                />
                <van-cell
                  :title="$t('币种')"
                  :value="orderData.sCurrencyName"
                />
                <van-cell :title="$t('汇率')" :value="orderData.fCurRate" />
                <van-cell :title="$t('运费')" :value="orderData.fExpFee" />
                <van-cell
                  :title="$t('其他费用')"
                  :value="orderData.fTotalExpense"
                  :label="$t('其他杂费合计')"
                />
                <van-cell
                  :title="$t('折扣')"
                  :value="orderData.fRebateAmount"
                  :label="$t('您的折扣/返点金额')"
                />
                <van-cell
                  :title="$t('合计')"
                  :value="
                    orderData.fTotalAmount == null
                      ? $t('未出账')
                      : orderData.fTotalAmount
                  "
                  :label="$t('账单应付款合计金额')"
                />
              </van-cell-group>
            </div>
          </div>
          <div class="bg-white set-center" v-else>
            <img :src="emptyImg" class="noimg" />
            <div>{{ $t('您的账单暂未生成') }}</div>
          </div>
        </van-tab>
      </van-tabs>
    </main>
  </div>
</template>
<script>
import { ImagePreview } from 'vant';
import { Timeline, TimelineItem, TimelineTitle } from 'vue-cute-timeline';
import {
  getOrderInfo,
  GetOrderPackageListByNo,
  getTracks,
} from '@/api/deliver.js';
import { formatDate } from '@/utils/index.js';
// @ is an alias to /src

function getValidTime(value) {
  if (value == null || value == '') return '';
  var myDate = new Date(value.match(/\d+/)[0] * 1);
  return formatDate(myDate, 'YYYY-MM-DD');
}
export default {
  components: {
    Timeline,
    TimelineItem,
    TimelineTitle,
  },
  data() {
    return {
      keyword: '',
      orderData: null,
      orderno: '',
      arriveTime: '',
      dpartTime: '',
      emptyImg: require('../../assets/images/noinfo.png'),
      pkglist: [],
      tracks: [],
      userInfo: this.$store.getters.userInfo,
    };
  },
  computed: {
    title() {
      const q = this.$route.query;
      return q.orderno;
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      var orderno = this.$route.query.orderno;
      this.orderno = orderno;
      getOrderInfo(orderno).then(res => {
        if (res.Result == true) {
          this.orderData = res.DataDict['data'];
          this.arriveTime = getValidTime(this.orderData.dArriveTime);
          this.departTime = getValidTime(this.orderData.dDepartTime);
        }
      });
      GetOrderPackageListByNo(orderno).then(res => {
        if (res.Result == true) {
          this.pkglist = res.DataDict['data'];
        }
      });

      getTracks({ orderNo: orderno }).then(res => {
        this.tracks = res.data;
      });
    },
    onImgPreview(imgurl) {
      if (imgurl == '' || imgurl == null) {
        return;
      }
      ImagePreview([imgurl]);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/_var';

.mb-x {
  margin-bottom: rem(40px);
}

.bg-white {
  background: #fff;
  padding: 5px;
  margin: 10px;
  border-radius: 5px;
}

/deep/ .timeline-title-circle {
  background-color: $theme-color;
}
/deep/ .timeline-item {
  border-bottom: 1px solid #f6f6f6;
}
.time {
  color: #666;
  font-size: 12px;
  margin-top: rem(20px);
}
.track-en {
  color: #979797;
}

.tab-padding {
  margin: rem(20px);
}
.card {
  padding: rem(20px);
  padding-top: 0;
  border-radius: rem(15px);
  box-shadow: 0px 2px 10px 0px rgba(14, 48, 53, 0.1);
  background-color: #fff;
  .card-header {
    color: #b8b8b8;
    font-size: 12px;
    border-bottom: 1px solid #efefef;
    height: rem(53px);
    line-height: rem(53px);
  }
  .card-body {
    padding: rem(20px) 0;
    line-height: rem(52px);
    .freight-price {
      color: #5b57b5;
      margin-right: rem(40px);
    }
  }
  .card-footer {
    color: #666;
    padding-top: rem(20px);
    height: rem(30px);
  }
  & + .card {
    margin-top: rem(20px);
  }
  .foot-desc {
    font-size: rem(13px);
  }
}
.padding {
  padding: rem(20px);
}
.img {
  width: rem(280px);
  height: rem(150px);
}
.noimg {
  width: auto;
  height: rem(150px);
}

.no-item {
  text-align: center;
  margin: rem(40px);
  img {
    height: rem(249px);
  }
  .no-desc {
    font-size: rem(13px);
    text-align: center;
  }
}

.round-label {
  background: #b9aebb;
  color: #fff;
  padding: 3px;
  border-radius: 3px;
  margin: 0 5px;
}
.order-info-item {
  border-color: #b9aebb;
  border: 1px solid;
  color: #b9aebb;
  padding: 0px 10px;
  border-radius: 4px;
  margin-right: rem(20px);
}
</style>
